'use client'

import type { ReactNode } from 'react'
import { SnackbarProvider } from 'notistack'

const ClientProviders = ({
	children,
}: {
	children: ReactNode
}) => {
	return (
		<SnackbarProvider maxSnack={3}>
			{children}
		</SnackbarProvider>
	)
}

export default ClientProviders
