import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.3.1_next@14.2.13_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@_kfgfmfxlmoropngbat4nie4g64/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.12_next@14.2.13_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1___5swv372v7hiay3fdbw2sr6ktb4/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.4/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.77.4/node_modules/next/font/local/target.css?{\"path\":\"src/app/(app)/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../fonts/euclid-circular-a/EuclidCircularA-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../../../fonts/euclid-circular-a/EuclidCircularA-BoldItalic.woff2\",\"weight\":\"700\",\"style\":\"italic\"},{\"path\":\"../../../fonts/euclid-circular-a/EuclidCircularA-Italic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../../../fonts/euclid-circular-a/EuclidCircularA-Light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../../fonts/euclid-circular-a/EuclidCircularA-LightItalic.woff2\",\"weight\":\"300\",\"style\":\"italic\"},{\"path\":\"../../../fonts/euclid-circular-a/EuclidCircularA-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../../fonts/euclid-circular-a/EuclidCircularA-MediumItalic.woff2\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../../../fonts/euclid-circular-a/EuclidCircularA-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../../fonts/euclid-circular-a/EuclidCircularA-SemiBold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../../fonts/euclid-circular-a/EuclidCircularA-SemiBoldItalic.woff2\",\"weight\":\"600\",\"style\":\"italic\"}],\"display\":\"block\"}],\"variableName\":\"circular\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(app)/_client-providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/(app)/_components/header.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.css");
