'use client'

import { usePathname } from 'next/navigation'
import Link from 'next/link'
import { ArrowUturnLeftIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import Logo from '~/components/Logo'

const Header = () => {
	const pathname = usePathname()

	const logoContainerClassList = classNames([
		pathname === '/' ? 'w-32' : 'w-16',
	])

	return (
		<header className="grid grid-cols-3 w-full max-w-7xl mx-auto">
			<div>
				{pathname !== '/' ? (
					<Link href="/">
						<span className="flex shrink items-center space-x-3 h-full cursor-pointer w-fit group">
							<ArrowUturnLeftIcon className="w-8 h-8" />
							<span className="text-xl font-medium border-2 border-transparent group-hover:border-b-zephyr-light">
								Back
							</span>
						</span>
					</Link>
				) : null}
			</div>
			<div className="flex justify-center">
				<div className={logoContainerClassList}>
					<Link href="/">
						<Logo className="fill-zephyr-blue" />
					</Link>
				</div>
			</div>
		</header>
	)
}

export default Header
